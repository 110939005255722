import Rest from '../Rest'

export default class FunctionalityService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/functionalities'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  // Permissions
  addPermissions(functionalityId, permissionIds) {
    return this.post(`/${functionalityId}/permissions`, {
      permissions: permissionIds
    })
  }

  destroyPermissionFunctionality(functionalityId, permissionId) {
    return this.delete(`/${functionalityId}/permissions/${permissionId}`)
  }

  listAllWithPermissions(roleId) {
    return this.get(`/roles/${roleId}/all`)
  }

}
