<template>
  <div>
    <div
      v-permission="'functionalities.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="functionalities"
      order_column="name">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('functionality.name') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('functionality.description') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="30%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="40%" key="description">
            <div v-html-safe="tr.description"></div>
          </vs-td>
          <vs-td width="10%">
            <feather-icon
              v-permission="'functionalities.edit'"
              icon="EditIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
              @click="props.edit(props.data[indextr].id)"
            />
            <feather-icon
              v-permission="'functionalities.delete'"
              icon="Trash2Icon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
              @click="props.destroy(props.data[indextr])"
            />
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import FunctionalityService from '@/services/api/FunctionalityService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/functionalities/create')
    }
  },
  beforeMount() {
    this.service = FunctionalityService.build(this.$vs)
  }
}
</script>
